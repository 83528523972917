import { Timestamp } from "firebase/firestore"; 
import type { EventType } from "../components/types/types";

export const getEvents = (): EventType[] => {
    return events();
}


const recurringEvents = (): EventType[] => {
    return [
        {
            "date": Timestamp.fromDate(new Date("June 6, 2025 12:00:00")),
            "repeating": true,
            "repeating_rate": "weekly",
            "consider_time": true,
            "title": "Friday Floggers stroke event",
            "description": "Tee off from midday, 9 holes, club open from 3pm"
        } ,
        {
            "title": "Stroke Monthly Mug",
            "date": Timestamp.fromDate(new Date("May 3, 2025")),
            "repeating": true,
            "repeating_rate": "first_saturday",
            "description": " Monthly event stroke mug",
            "time": false
        } ,
        {
            "repeating_rate": "second_saturday",
            "consider_time": false,
            "repeating": true,
            "title": "Stableford",
            "description": "",
            "date": Timestamp.fromDate(new Date("April 12, 2025"))
        } ,
        {
            "repeating_rate": "second_saturday",
            "consider_time": false,
            "repeating": true,
            "title": "Stableford",
            "description": "",
            "date": Timestamp.fromDate(new Date("April 26, 2025"))
        } ,
        {
            "repeating_rate": "weekly_tuesday",
            "consider_time": false,
            "repeating": true,
            "title": "Vets and Ladies",
            "description": "7am and 7:30am starts",
            "date": Timestamp.fromDate(new Date("Febuaray 25, 2025"))
        }
    ]
}
const events = (): EventType[] => {
    return recurringEvents().concat(singleEvents());
}

const singleEvents = (): EventType[] => {
    return [
        {
            "title": "Stableford",
            "description": "",
            "consider_time": false,
            "date": Timestamp.fromDate(new Date("March 22, 2025")),
            "repeating": false
        },
        {
            "description": "Sponsored by Calo - Bond Roofing",
            "title": "Stroke Monthly Mug. Championship Round 1",
            "date": Timestamp.fromDate(new Date("March 1, 2025")),
            "consider_time": false,
            "repeating": false
        }, 
        {
            "description": "Championship round",
            "title": "Stroke. Championship Round 2",
            "date": Timestamp.fromDate(new Date("March 8, 2025")),
            "consider_time": false,
            "repeating": false
        },
        {
            "description": "Championship round",
            "title": "Stroke. Championship Round 3",
            "date": Timestamp.fromDate(new Date("March 15, 2025")),
            "consider_time": false,
            "repeating": false
        },
        {
            "description": "Championship round Seeded Draw",
            "title": "Stroke. Championship Round 4",
            "date": Timestamp.fromDate(new Date("March 16, 2025")),
            "consider_time": false,
            "repeating": false
        },
        {
            "title": "Stroke Monthly Mug",
            "date": Timestamp.fromDate(new Date("April 5, 2025")),
            "description": "Sponsored by TechMobile",
            "time": false,
            "repeating": false
        }, {
            "description": "Sponsored by Russ's Fresh Bake",
            "title": "Easter 4BBB Stableford",
            "date": Timestamp.fromDate(new Date("April 19, 2025")),
            "consider_time": false,
            "repeating": false
        },
        {
            "description": "TORO -- Stoke 7am and 11:30am",
            "title": "Karuah Open",
            "date": Timestamp.fromDate(new Date("April 27, 2025")),
            "consider_time": false,
            "repeating": false
        },
    ];
}

export const castTime = (date: Date) => {    
    let formatter = new Intl.DateTimeFormat('en-US', {
        hour: '2-digit',
        minute: '2-digit',
        hour12: true
    });
    
    let formattedTime = formatter.format(date);
    return formattedTime;
}

export const filterEvents = (events: EventType[]): EventType[]  => {
    const now = new Date();
    const filteredEvents = events.filter(event => event.date.toDate() >= now);
    return filteredEvents;
}

export const generateRepeatingEvents = (event: EventType): EventType[] => {
    const { repeating, repeating_rate } = event;
    const generatedEvents: EventType[] = [];
    
    if (!repeating || !repeating_rate) {
        return [event]; // Return the original event if it's not repeating
    }

    let currentEventDate = event.date.toDate();
    const sevenMonthsLater = new Date();
    sevenMonthsLater.setMonth(sevenMonthsLater.getMonth() + 7);

    while (currentEventDate <= sevenMonthsLater) {
        generatedEvents.push({
            ...event,
            date: Timestamp.fromDate(new Date(currentEventDate))
        });

        // Calculate the next date based on repeating_rate
        switch (repeating_rate) {
            case "weekly":
                currentEventDate.setDate(currentEventDate.getDate() + 7);
                break;
            case "monthly":
                currentEventDate.setMonth(currentEventDate.getMonth() + 1);
                break;
            case "yearly":
                currentEventDate.setFullYear(currentEventDate.getFullYear() + 1);
                break;
            case "weekly_tuesday": {
                // Move to the next Tuesday
                const currentDay = currentEventDate.getDay(); // 0 = Sunday, 1 = Monday, ..., 6 = Saturday
                const daysUntilNextTuesday = (2 - currentDay + 7) % 7 || 7;
                currentEventDate.setDate(currentEventDate.getDate() + daysUntilNextTuesday);
                break;
            }
            case "first_saturday":
                // Move to the first day of the next month
                currentEventDate.setMonth(currentEventDate.getMonth() + 1);
                currentEventDate.setDate(1);

                // Find the first Saturday of the month
                while (currentEventDate.getDay() !== 6) { // 6 is Saturday
                    currentEventDate.setDate(currentEventDate.getDate() + 1);
                }
                break;
            case "last_friday":
                currentEventDate.setMonth(currentEventDate.getMonth() + 1);
                currentEventDate.setDate(0); // Set to last day of the previous month
                while (currentEventDate.getDay() !== 5) { // 5 is Friday
                    currentEventDate.setDate(currentEventDate.getDate() - 1);
                }
                break;
            case "second_saturday":
                // Move to the first day of the next month
                currentEventDate.setMonth(currentEventDate.getMonth() + 1);
                currentEventDate.setDate(1);
                // Find the first Saturday of the month
                while (currentEventDate.getDay() !== 6) { // 6 is Saturday
                    currentEventDate.setDate(currentEventDate.getDate() + 1);
                }
                // Move to the second Saturday of the month
                currentEventDate.setDate(currentEventDate.getDate() + 7);
                break; 
            case "last_saturday":
                   // Move to the last day of the current month
                    currentEventDate.setMonth(currentEventDate.getMonth() + 1);
                    currentEventDate.setDate(0); // Set to last day of the previous month, effectively last day of the current month

                    // Find the last Saturday of the month
                    while (currentEventDate.getDay() !== 6) { // 6 is Saturday
                        currentEventDate.setDate(currentEventDate.getDate() - 1);
                    }
                    break; 
            default:
                currentEventDate.setFullYear(currentEventDate.getFullYear() + 1);
                break;
        }
    }

    return generatedEvents;
};