import { FacilityType } from "../components/types/types";

export const facilitiesData: FacilityType[] = [
  {
    image: "./images/course_images/Karuah-GC.png",
    title: "The Course",
    text: "Karuah Golf Club is a tree-lined 9 hole golf course. It is a casual and friendly, voluntary-run golf course.",
    reverse: true,
  },
  {
    image: "./images/course_images/vendi.png",
    title: "Carts",
    text: "We offer Vendi tap-and-pay carts for $30.",
    reverse: false,
  },
  {
    image: "./images/course_images/karuah_golf_resized.jpeg",
    title: "Clubhouse Available for Functions",
    text: "Full kitchen - Bar available - Birthdays, corporate events etc.",
    reverse: true,
  },
  {
    image: "./images/course_images/golf-balls.jpeg",
    title: "Social Group Bookings",
    text: "Carts available - BBQ facilities.",
    reverse: false,
  },
  {
    image: "./images/course_images/group_golf.JPG",
    title: "Competition and Vet Competition Pricing",
    text: "Saturday/Sunday are $25 for members and $30 for non-members. This includes 18 holes and participation in the competition.",
    reverse: true,
  },
  {
    title: "General Pricing",
    text: "18 holes is $25 and 9 holes $20.",
    reverse: true,
  },
];
