import React from 'react';
import './Footer.css';
import footerContent from "../../content/footerElement.json";
import { ADDRESS, EMAIL, FACEBOOK_GROUP, PHONE_NUMBER } from '../../utils/constants';

const Footer: React.FC = () => {
    return (
        <footer className="footer">
            <div className="footer-content">
                <div className="footer-section address">
                    <h4>{footerContent.addressTitle}</h4>
                    <p>{ADDRESS}</p>
                </div>
                <div className="footer-section contact">
                    <h4>{footerContent.contactTitle}</h4>
                    <p>Email: {EMAIL}</p>
                    <p>Phone: {PHONE_NUMBER}</p>
                </div>
                <div className="footer-section social">
                    <h4>{footerContent.facebookTitle}</h4>
                    <a href={FACEBOOK_GROUP} target="_blank" rel="noopener noreferrer">
                        <img className="facebookImg" src="images/icons/facebook.png" alt="Facebook" />
                    </a>
                    <p></p>
                </div>
            </div>
            <div className='icons'>
                <p title="event icons">{footerContent.contentShoutout.mainMessage}</p>
                <p>{footerContent.contentShoutout.shoutouts}</p>
            </div>
        </footer>
    );
}

export default Footer;
