import { useState, useEffect, useRef } from "react";
import { NavLink } from "react-router-dom";
import { IoClose, IoMenu } from "react-icons/io5";
import "./Navbar.css";
import navbarCopy from "../../content/navbarElement.json";

const Navbar = () => {
  const [showMenu, setShowMenu] = useState(false);
  const navMenuRef = useRef<HTMLDivElement>(null);

  const toggleMenu = () => {
    setShowMenu(!showMenu);
  };

  const closeMenuOnMobile = () => {
    if (window.innerWidth <= 1150) {
      setShowMenu(false);
    }
  };

  const handleClickOutside = (event: MouseEvent) => {
    if (navMenuRef.current && !navMenuRef.current.contains(event.target as Node)) {
      setShowMenu(false); // Close the menu if clicking outside of it
    }
  };

  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  return (
    <header className="header">
      <nav className="nav container">
        <NavLink to="/" className="nav__logo">
          {navbarCopy.iconName}
        </NavLink>

        <div
          className={`nav__menu ${showMenu ? "show-menu" : ""}`}
          id="nav-menu"
          ref={navMenuRef}
        >
          <ul className="nav__list">
            <li className="nav__item">
              <NavLink to="/" className="nav__link" onClick={closeMenuOnMobile}>
                {navbarCopy.home}
              </NavLink>
            </li>
            <li className="nav__item">
              <NavLink
                to="/facilities"
                className="nav__link"
                onClick={closeMenuOnMobile}
              >
                {navbarCopy.facilities}
              </NavLink>
            </li>
            <li className="nav__item">
              <NavLink
                to="/events"
                className="nav__link"
                onClick={closeMenuOnMobile}
              >
                {navbarCopy.events}
              </NavLink>
            </li>
            <li className="nav__item">
              <NavLink
                to="/membership"
                className="nav__link"
                onClick={closeMenuOnMobile}
              >
                {navbarCopy.membership}
              </NavLink>
            </li>
            <li className="nav__item">
              <NavLink
                to="/sponsorship"
                className="nav__link"
                onClick={closeMenuOnMobile}
              >
                {navbarCopy.sponsorship}
              </NavLink>
            </li>
            <li className="nav__item">
              <NavLink
                to="/contact-us"
                className="nav__link"
                onClick={closeMenuOnMobile}
              >
                {navbarCopy.contact}
              </NavLink>
            </li>
            <li className="nav__item">
              <NavLink to="/make-reservation" className="nav__link nav__cta">
                {navbarCopy.reservation}
              </NavLink>
            </li>
          </ul>
          <div className="nav__close" id="nav-close" onClick={toggleMenu}>
            <IoClose />
          </div>
        </div>

        <div className="nav__toggle" id="nav-toggle" onClick={toggleMenu}>
          <IoMenu />
        </div>
      </nav>
    </header>
  );
};

export default Navbar;
