import React from 'react';
import './Facilities.css';
import { useNavigate } from 'react-router-dom';
import { facilitiesData } from "../../utils/facility_helper";
import { PHONE_NUMBER } from '../../utils/constants';
export const FacilityTile: React.FC<{ image?: string, title: string, text: string, reverse?: boolean }> = ({ image, title, text, reverse }) => {
    const navigate = useNavigate();
    const handleClick = () => {
        // Navigate to the /make-reservation page with a query parameter
        navigate(`/membership`);
    };

  function getWindowDimensions() {
      const { innerWidth: width, innerHeight: height } = window;
      return {
        width,
        height
      };
    }

    const additionalPricingCopy = () => {
      return <>
        <p>Junior rates: 18 holes $7 and 9 holes $5</p>
        <p>Payment for the round can be made on Vendi tap & pay or via cash box</p>
        <p>Interested in becoming a member?</p>
        <div className="button-container">
              {title === "General Pricing" && (
              <button className="become-member-button" onClick={handleClick}>Membership Information</button>
              )}
        </div>
      </>;
    }

  return (
    <div className={`facility-tile ${reverse ? 'reverse' : ''}`}>
      {(image && getWindowDimensions().width > 730) && (<div className="image-container">
        <img src={image} alt={title} />
      </div>)}
      <div className="text-container">
        <h2>{title}</h2>
        {title === "General Pricing" && (<p>Non-member pricing:</p>)}
        <p>{text}</p>
        {title === "General Pricing" && (additionalPricingCopy())}
        {title === "Competition and Vet Competition Pricing" && (<p>Vets Tuesdays has a $20 entry fee</p>)}
        {(title === "Social Group bookings" || title === "Clubhouse available for functions") && (<p>Contact the club for bookings. Ph. {PHONE_NUMBER}</p>)}
        {title === "Karuah Open Entry Form" && (
          <div>
              <a href={'/files/Karuah Golf Club Toro-Open 2025.pdf'} className="download-link" download>
                {"Entry Form Download"}
            </a>
          </div>
        )}
      </div>
    </div>
  );
};

const FacilitiesPage: React.FC = () => {
  return (
    <div className="facilities-page">
      <h1>Our Facilities</h1>
      
      {Object.values(facilitiesData).map(({ image, title, text, reverse }, index) => (
        <FacilityTile key={index} image={image} title={title} text={text} reverse={reverse} />
      ))}
    </div>
  );
};

export default FacilitiesPage;
