import React from 'react';
import './Contact.css';
import { ADDRESS, EMAIL, FACEBOOK_GROUP, PHONE_NUMBER } from '../../utils/constants';

const Contact: React.FC = () => {
  return (
    <div className="contact-us-container">
      <h1>Contact Us</h1>
      <div className="general-contact">
        <h2>General Contact Information</h2>
        <p><strong>Address: </strong>{ADDRESS}</p>
        <p><strong>Phone: </strong>{PHONE_NUMBER}</p>
        <p><strong>Email: </strong> <a href="mailto:karuahgolf@outlook.com">{EMAIL}</a></p>
        <div className="facebook-link">
            <p><strong>Facebook Group:</strong></p>
            <a href={FACEBOOK_GROUP} target="_blank" rel="noopener noreferrer">
                <p>Karuah Golf Club Facebook Page</p>
            </a>
        </div>
      </div>
    </div>
  );
};

export default Contact;