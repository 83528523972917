import React from 'react';
import './Membership.css'; // Custom CSS for styling
import './Sponsorship.css'; // Custom CSS for styling
import MembershipPricing from '../elements/membership/MembershipPricing';
import MembershipForm from '../elements/membership/MembershipForm';
import MembershipBenefits from '../elements/membership/MembershipBenefits';
import { SponsorCarousel } from '../elements/SponsorCarousel';
import sponsorshipCopy from '../../content/sponsorshipPage.json';
import { EMAIL, PHONE_NUMBER } from '../../utils/constants';
const Sponsorship: React.FC = () => {
    
    const updatedSponsorshipData = {
        ...sponsorshipCopy,
        instructions: [
            ...sponsorshipCopy.instructions,
            `Email ${EMAIL}`,
            `Phone ${PHONE_NUMBER}`
        ]
    };
      

    return ( 
            <div>
                <div className="membership">
                <div className="membership-header">
                    <img src={updatedSponsorshipData.logo} alt="Logo" className="info-logo" />
                    <h1>About our Sponsorshop</h1>
                </div>
                <MembershipBenefits title={"Sponsorship Benefits"} benefits={updatedSponsorshipData.benefits} />
                <MembershipPricing title={"Sponsorship Pricing"} price={updatedSponsorshipData.price} priceSecondary={updatedSponsorshipData.priceSecondary} />
                <MembershipForm
                    buttonName="Download Information"
                    title="Sponsorship Information"
                    formLink={updatedSponsorshipData.formLink}
                    instructions={updatedSponsorshipData.instructions}
                />
            </div>
            <div className='membership-header sponsorship-footer'>
                <h1>Thanks to our amazing sponsors</h1>
                <div className='sponsorship'>
                </div>
                <SponsorCarousel background-color={"none"}/>
            </div>
         </div>

            
       
    );
};

export default Sponsorship;
