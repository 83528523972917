import {SponsorCarousel} from '../elements/SponsorCarousel';
import InfoSection from '../elements/InfoSection';
import TiledSection from '../elements/TiledSection';
import './Home.css';
import { MAIN_LOGO } from '../../utils/constants';
import homePageCopy from "../../content/homePage.json";

const Home = () => {
    return (
        <div className="homepage">
            <header className="homepage-header">
                <InfoSection 
                    logo={MAIN_LOGO} 
                    title={homePageCopy.title} 
                    description={homePageCopy.description} 
                    image={homePageCopy.mainImagePath}
                />
            </header>
            <main className="homepage-content">
                <section className="homepage-tiles">
                    <TiledSection tiles={homePageCopy.pageTileContent} />
                </section>
                <div className="homepage-sponsors">
                    <SponsorCarousel />
                </div>
            </main>
        </div>
    );
};

export default Home;