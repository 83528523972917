import { useEffect, useState } from "react";
import { filterEvents, generateRepeatingEvents, getEvents } from "../../utils/event_helper";
import type { EventType } from "../types/types";
import { Event } from "../elements/Event";
import './Events.css';
import { PHONE_NUMBER } from "../../utils/constants";


const Events = () => {
    const [events, setEvents] = useState<EventType[]>([]);  

    const fetchEvents = async () => {
        const allEvents =  getEvents().flatMap(event => generateRepeatingEvents(event));
        const filteredEvents = filterEvents(allEvents);
        setEvents(filteredEvents);
    }

    useEffect(() => {
        fetchEvents();
    }, []);

    // Group events by month and sort them
    const groupedEvents = events.reduce<{ [key: string]: EventType[] }>((acc, event) => {
        const eventDate = event.date.toDate();
        const month = eventDate.toLocaleString('default', { month: 'long', year: 'numeric' });

        if (!acc[month]) {
            acc[month] = [];
        }
        acc[month].push(event);
        return acc;
    }, {});

    // Sort months by date
    const sortedMonths = Object.keys(groupedEvents).sort((a, b) => {
        const aDate = new Date(a);
        const bDate = new Date(b);
        return aDate.getTime() - bDate.getTime();
    });


    return (
        <div className="event-list-container">
            { events.length > 0 ? <div className="event_list">
                <h1 className="events__title">Events</h1>
                <div className="contact-section">
                    <h2>Can't find the event you're looking for?</h2>
                    <p>Please contact the club on {PHONE_NUMBER}.</p>
                </div>
                {sortedMonths.map(month => (
                    <div key={month} className="month-group">
                        <h2 className="month-title">{month}</h2>
                        {groupedEvents[month]
                            .sort((a, b) => a.date.toDate().getTime() - b.date.toDate().getTime())
                            .map(event => (
                                <Event event={event} />
                            ))}
                    </div>
                ))} 
            </div> : <div /> }
        </div>
    )
}

export default Events;