import { useNavigate } from "react-router-dom";
import { castTime } from "../../utils/event_helper";
import { EventType } from "../types/types";
import './Event.css';

type EventProps = {
    event: EventType;
}

export const Event = ({event}: EventProps) => {
    const navigate = useNavigate();
    const isPastEvent = event.date.toDate() < new Date();

    const eventDate = () => {
        const date = event.date.toDate();
        if(event.consider_time) {
            if(event.date2) {
                const date2 = event.date2.toDate();
                return `${castTime(date)} - ${castTime(date2)} ${event.date2_purpose ? `${event.date2_purpose}` : ''}`;
            }
            return castTime(event.date.toDate());
        }
    }

    const handleClick = () => {
        // Navigate to the /make-reservation page with a query parameter
        navigate(`/make-reservation?name=${encodeURIComponent(event.title)}&year=${encodeURIComponent(event.date.toDate().getFullYear())}&month=${encodeURIComponent(event.date.toDate().getMonth() + 1)}&day=${encodeURIComponent(event.date.toDate().getDate())}`);
    };

    // Remove if we want to show results
    if(isPastEvent) {
        return <></>;
    }

    return (
        <div className={`event-card ${isPastEvent ? 'event-card--disabled' : ''}`}>
            <div className="event-content">
                <h2 className="event-title">{event.title}</h2>
                <p className="event-date">{event.date.toDate().toDateString()}</p>
                <p className="event-time">{eventDate()}</p>
                <p className="event-description">{event.description}</p>
                {!isPastEvent&& <button className="register-button" onClick={handleClick}>Register</button>}
            </div>
        </div>
    )
}
