import React from 'react';
import './Membership.css'; // Custom CSS for styling
import MembershipPricing from '../elements/membership/MembershipPricing';
import MembershipForm from '../elements/membership/MembershipForm';
import MembershipBenefits from '../elements/membership/MembershipBenefits';
import { MAIN_LOGO } from '../../utils/constants';
import membershipContent from '../../content/membershipPage.json';

const Membership: React.FC = () => {

    // TODO: Remove email from membership copy file and use standard const email
    return ( 
            <div className="membership">
                <div className="membership-header">
                    <img src={MAIN_LOGO} alt="Logo" className="info-logo" />
                    <h1>{membershipContent.pageTitle}</h1>
                </div>
                <MembershipBenefits
                    title={membershipContent.benefitsTitle}
                    benefits={membershipContent.benefits} />
                <MembershipPricing
                    title={membershipContent.priceTitle}
                    price={membershipContent.price} />
                <MembershipForm
                    buttonName={membershipContent.buttonName}
                    secondaryButtonName={membershipContent.secondaryButtonName}
                    title={membershipContent.membershipTitle}
                    formLink={membershipContent.formLink}
                    secondaryFormLink={membershipContent.secondaryFormLink}
                    instructions={membershipContent.instructions}
                    postSubmissionInstructions={membershipContent.postInstructions}
                />
            </div>
       
    );
};

export default Membership;
