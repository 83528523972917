import { useLocation } from "react-router-dom";
import { Form } from "../elements/Form";
import { FacilityTile } from "./Facilities";
import './MakeReservation.css';

const MakeReservation = () => {
    const location = useLocation();
    const query = new URLSearchParams(location.search);
    const name = query.get('name') || "";
    const year = query.get('year') || "";
    const month = query.get('month') || "";
    const day = query.get('day') || "";

    // Corrected the URL by removing the second question mark
    const baseUrl = 'https://docs.google.com/forms/d/e/1FAIpQLSdO49CtCk4vxc9Z0OESDCsn8s9La8CY5hzsl6Xr7Asy8UgnjA/viewform?embedded=true';

    return (
        <div className="reservation-container">
            <FacilityTile title="Karuah Open Entry Form" text='Please download and fill out the form below to make a reservation for the Karuah Open of the 27th of April and send to karuahgolf@outlook.com once filled out.' />
            <h2>For all other reservations please fill out the following enquiry form:</h2>
            <Form 
                src={`${baseUrl}&usp=pp_url&entry.330058456_year=${encodeURIComponent(year)}&entry.330058456_month=${encodeURIComponent(month)}&entry.330058456_day=${encodeURIComponent(day)}&entry.1385327982=${encodeURIComponent(name)}`} 
                loadingText="Loading..."
            />
        </div>
    )
}

export default MakeReservation;
