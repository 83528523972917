import React from 'react';
import './App.css';
import Navbar from './components/elements/Navbar';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import MakeReservation from './components/pages/MakeReservation';
import Events from './components/pages/Events';
import Home from './components/pages/Home';
import Footer from './components/elements/Footer';
import ScrollToTop from './utils/ScrollToTop';
import Membership from './components/pages/Membership';
import Contact from './components/pages/Contact';
import Facilities from './components/pages/Facilities';
import Sponsorship from './components/pages/Sponsorship';

function App() {
  return (
    <>
      <Router>
        <ScrollToTop />
        <Navbar />
        <main className="main-content">
          <div className="container">
            <Routes>
              <Route path="/make-reservation" element={<MakeReservation />}/>
              <Route path="/facilities" element={<Facilities />}/>
              <Route path="/events" element={<Events />}/>
              <Route path="/membership" element={<Membership />}/>
              <Route path="/sponsorship" element={<Sponsorship />}/>
              <Route path="/contact-us" element={<Contact />}/>
              <Route path="/" element={<Home />} />
            </Routes>
          </div>
        </main>
      </Router>
      <Footer />
    </>
  );
}

export default App;
